<template>
  <layout :loading="loading">
    <div class="row">
      <div class="col-sm-2">
        <h2>Team</h2>
      </div>
      <div class="col-sm-10">
        <div class="link-group">
          <search-input v-model="search" class="mb-1 mb-sm-0 mr-sm-2" />
          <router-link to="/team/hours-of-availability" class="btn btn-primary mr-sm-2"><i class="fa fa-clock mr-2"></i>Hours of availability</router-link>
          <router-link to="/birthdays/calendar" class="btn btn-primary d-sm-none d-inline"><i class="fas fa-birthday-cake mr-2"></i>Birthdays calendar</router-link>
          <router-link
            v-if="isShowSubordinate"
            to="/subordinates"
            class="btn btn-primary ml-2 d-sm-none d-inline"
          >
            <div class="d-flex align-items-center justify-content-center">
              <i class="bx bxs-user-detail font-size-18 mr-2" />
              Subordinates
            </div>
          </router-link>
          <b-dropdown
            right
            variant="primary"
            class="team__settings-button d-none d-sm-inline-flex"
          >
            <template #button-content>
              <i class="bx bx-dots-vertical-rounded" />
            </template>
            <b-dropdown-item to="/birthdays/calendar">
              <div class="d-flex align-items-center">
                <i class="bx bxs-cake font-size-16" />
                Birthdays calendar
              </div>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isShowSubordinate"
              to="/subordinates"
            >
              <div class="d-flex align-items-center">
                <i class="bx bxs-user-detail font-size-16" />
                Subordinates
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <empty-page v-if="!Object.entries(list).length && search" />
    <div
      class="mb-4"
      v-for="([key, value]) of Object.entries(list)"
      :key="key"
    >
      <h4 class="mb-3">{{key}}</h4>
      <div class="row">
        <div
          class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-2"
          v-for="([k, val]) of Object.entries(value)"
          :key="k"
        >
          <b-card
            class="text-center team-member"
            v-if="val[0]"
          >
            <div class="team-member__photo mb-3">
              <img
                :src="val[0].photo || AvatarDefault"
                alt="Header Avatar"
              />
            </div>
            <h5 class="font-size-18 mb-1">{{ val[0].name }}</h5>
            
            <div class="text-muted d-flex justify-content-center" :class="p_index != 0 ? 'mt--17' : ''" v-for="(p,p_index) in val" :key="p+p_index">
              <span v-if="val.length > 1">
                -
              </span>
              <p>
                {{ p.position ? p.position.title  : '' }}
              </p>
            </div>
            <div class="mb-1">
              <a :href="`mailto:${val[0].email}`">{{ val[0].email }}</a>
            </div>
            <div>
              <a :href="`tel:${val[0].phone}`">{{ val[0].phone }}</a>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import EmptyPage from '@/components/empty-page.vue';
import SearchInput from '@/components/search-input.vue';
import AvatarDefault from '@/assets/images/users/avatar-default-rect.png';

export default {
  name: 'Team',
  components: {
    EmptyPage,
    SearchInput
  },
  data() {
    return {
      search: '',
      AvatarDefault,
      displayCoin: process.env.VUE_APP_DISPLAY_BIMI_COINS,
      isShowSubordinate: false
    }
  },
  async mounted() {
    await this.$store.dispatch('team/getTeam');
    await this.$store.dispatch('team/checkSubordinateStatus').then(res => {
      if (res) {
        this.isShowSubordinate = res.data.message
      }
    })

  },
  methods: {
    filterDepartmentsList(list) {
      const departments = {};
      Object.keys(list).forEach(departName => {  
        const filteredDepartment = this.filterDepartment(list[departName]);
        if (Object.keys(filteredDepartment).length) {
          departments[departName] = filteredDepartment;
        }
      });
      return departments;
    },
    filterDepartment(department) {
      const newDepartment = {};
      Object.entries(department).forEach(([email, data]) => {
        if (this.includesSearch(email)) {
          newDepartment[email] = data;
          return;
        }
        const hasIncludes = data.some(
          (item) => this.includesSearch(item.name) ||
          this.includesSearch(item.position?.title) ||
          this.includesSearch(item?.phone)
        );
        if (hasIncludes) {
          newDepartment[email] = data;
        }
      });

      return newDepartment;
    },
    includesSearch(value) {
      const str = value?.toLowerCase() ?? '';
      return (str).includes(this.search.toLocaleLowerCase());
    }
  },
  computed: {
    list() {
      const list = {...this.$store.getters['team/teamByDepartmets']};      
      if (!this.search) {
        return list;
      }

      return this.filterDepartmentsList(list);
    },
    profile() {
      return this.$store.state.profile.profile;
    },
    loading() {
      return this.$store.state.team.loading;
    }
  }
}
</script>

<style lang="scss" scoped>
.team {
  &__settings-button {
    width: 30px;
    height: 36.53px;
    position: relative;

    .bx-dots-vertical-rounded {
      top: 50%;
      left: 50%;
      font-size: 20px;
      position: absolute;
      transform: translate(-50%, -50%);
    }

    .dropdown-menu {
      margin-top: 5px;
    }
    .dropdown-item {
      font-size: 13px;
      padding: 5px 20px;

      i {
        margin-right: 10px;
      }
    }
  }
}
  .team-member {
    overflow: hidden;
    &__photo {
      width: 100%;
      padding-top: 100%;
      position: relative;
      img {
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }
    }
    .card-body {
      padding: 0 0 1.25rem 0;
    }
    .mt--17{
      margin-top: -17px;
    }
  }
  .link-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }



  @media screen and (max-width: 575px) {
    .link-group {
      flex-direction: column;
      a {
        margin-bottom: 5px;
        margin-left: 0 !important;
      }
      & > a, 
      & > div {
        width: 100%;
      }
    }
  }
</style>
