<template>
  <div class="search-input">
    <i class="bx bx-search search-input__search-icon" />
    <i
      v-if="input"
      class="mdi mdi-close-circle search-input__clear-icon"
      @click="input = ''"
    />
    <b-form-input
      v-model="input"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    value: String,
    placeholder: {
      type: String,
      default: 'Type to search...'
    },
  },
  computed: {
    input: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-input {
  height: 36px;
  position: relative;

  .form-control {
    height: 36px;
    padding: 0px 30px 0px 36px;
  }

  &__search-icon {
    top: 50%;
    left: 10px;
    color: #2A3042;
    font-size: 16px;
    position: absolute;
    transform: translateY(-50%);
  }

  &__clear-icon {
    top: 50%;
    right: 5px;
    opacity: 0.3;
    cursor: pointer;
    color: #2A3042;
    font-size: 20px;
    position: absolute;
    transform: translateY(-50%);
  }
}
</style>
